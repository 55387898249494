import Stack, {
  onEntryChange,
} from '@shared/contentstack/live-preview/live-preview';
import { personCardsMapper } from '@src/shared/components/leadership-page/mappers/personCardsMapper';
import { StaticPersonCard } from '@src/shared/components/person-cards/static-person-card/static-person-card';
import { useCallback, useEffect, useState } from 'react';

const PersonCardPreviewPage = (props: any) => {
  const { pageContext } = props;

  const [livePreviewData, setLivePreviewData] = useState<any>({});

  Stack.startLivePreview(true);

  const fetchData = useCallback(async () => {
    const response = (await Stack.getEntry({
      contentTypeUid: 'person_card',
      url:
        pageContext?.url && pageContext?.url !== '/'
          ? pageContext?.url
          : '/live-preview',
    })) as any;
    const result = response[0][0];

    setLivePreviewData(personCardsMapper([result], result.headshot));
  }, [pageContext]);

  useEffect(() => {
    onEntryChange(() => fetchData());
  }, [fetchData]);

  return !!livePreviewData && <StaticPersonCard {...livePreviewData} />;
};

export default PersonCardPreviewPage;
